import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../utils/axiosConfig';
import { Image, FileText, Folder, LayoutGrid, List, Download, Trash2, ArrowLeft, Users, EllipsisVertical, Edit2 } from 'lucide-react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../input.css';
import CreateFolderModal from './modals/CreateFolderModal';
import EditAccessLevelModal from './modals/EditAccessLevelModal';

// สร้าง Loading Skeleton สำหรับโฟลเดอร์
const FolderSkeleton = ({ variant = 'list' }) => {
  if (variant === 'grid') {
    return (
      <div className="border p-4 bg-white rounded-lg shadow animate-pulse">
        <div className="flex justify-between items-start">
          <div className="flex-grow">
            <div className="flex items-center mb-2">
              <div className="w-6 h-6 bg-gray-200 rounded-md mr-2"></div>
              <div className="h-6 w-36 bg-gray-200 rounded"></div>
            </div>
            <div className="mt-2 min-h-[20px]">
              <div className="h-5 w-20 bg-gray-200 rounded"></div>
            </div>
          </div>
          <div className="h-8 w-8 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex hover:bg-gray-50 transition animate-pulse">
      <div className="w-2/3 px-4 py-3">
        <div className="flex items-center min-h-[40px]">
          <div className="w-6 h-6 bg-gray-200 rounded-md mr-2"></div>
          <div className="h-5 w-32 bg-gray-200 rounded"></div>
        </div>
        <div className="mt-1 min-h-[20px]">
          <div className="h-4 w-20 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="w-1/3 px-4 py-3 flex items-center justify-center">
        <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  );
};

// สร้าง Loading Skeleton สำหรับไฟล์
const FileSkeleton = ({ variant = 'list' }) => {
  if (variant === 'grid') {
    return (
      <div className="border p-4 rounded-lg shadow bg-white animate-pulse">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-2">
            <div className="w-6 h-6 bg-gray-200 rounded-md flex-shrink-0"></div>
            <div className="h-5 w-28 bg-gray-200 rounded"></div>
          </div>
          <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
        </div>
        <div className="h-4 w-20 bg-gray-200 rounded"></div>
      </div>
    );
  }
  
  return (
    <div className="flex items-center hover:bg-gray-50 transition animate-pulse">
      <div className="w-1/3 px-4 py-3 min-h-[40px] flex items-center">
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 bg-gray-200 rounded-md flex-shrink-0"></div>
          <div className="h-4 w-28 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center">
        <div className="h-4 w-20 bg-gray-200 rounded"></div>
      </div>
      <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center justify-center">
        <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  );
};

// ส่วนค่าคงที่
const currentUser = JSON.parse(localStorage.getItem('currentUser'));
const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

// ส่วนฟังก์ชันนอกคอมโพเนนต์
const renderAccessLevelBadges = (accessLevel) => {
  if (!accessLevel || accessLevel === 'all') {
    return <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center h-5">
      <span className="h-2 w-2 bg-gray-400 rounded-full mr-1"></span>
      ทุกแผนก
    </span>;
  }
  
  // แยกแผนกที่มีสิทธิ์
  const accessLevels = accessLevel.split(',');
  
  // แก้ไขเพิ่ม className="justify-start w-full" เพื่อให้ชิดซ้าย
  return (
    <div className="flex flex-wrap gap-1 justify-start w-full">
      {accessLevels.map(level => {
        const dept = departments.find(d => d.id === level);
        return dept ? (
          <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center h-5`}>
            <span className={`h-2 w-2 ${dept.color.includes('blue') ? 'bg-blue-500' : 
                             dept.color.includes('green') ? 'bg-green-500' : 
                             dept.color.includes('yellow') ? 'bg-yellow-500' : 
                             dept.color.includes('purple') ? 'bg-purple-500' : 
                             'bg-red-500'} rounded-full mr-1`}></span>
            {dept.label}
          </span>
        ) : null;
      })}
    </div>
  );
};

const ICON_SIZES = {
  small: "w-4 h-4",
  default: "w-5 h-5",
  medium: "w-6 h-6",
  large: "w-7 h-7"
};

const FilesManager = () => {
  // เพิ่ม state สำหรับ loading
  const [loading, setLoading] = useState({
    folders: true,
    files: true
  });
  
  // state เดิมที่มีอยู่แล้ว
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [folderPath, setFolderPath] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [foldersViewMode, setFoldersViewMode] = useState('list');
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditAccessModalOpen, setIsEditAccessModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [folderToRename, setFolderToRename] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  
  // 1. ย้าย showAlert ขึ้นมาก่อนเพื่อให้ฟังก์ชันอื่นสามารถใช้งานได้
  const showAlert = (icon, title, text, type = 'toast') => {
    if (type === 'modal') {
      return Swal.fire({
        icon, title, text,
        confirmButtonText: 'เข้าใจแล้ว',
        confirmButtonColor: '#3085d6',
      });
    }
    
    return Swal.fire({
      icon, title, text,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };
  
  // 2. เริ่มจากฟังก์ชันที่ไม่ขึ้นกับฟังก์ชันอื่น
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    try {
      // แปลง ISO string เป็น Date object
      const date = new Date(dateString);
      
      // ตรวจสอบว่าเป็นวันที่ที่ถูกต้องหรือไม่
      if (isNaN(date.getTime())) {
        return dateString; // ถ้าแปลงไม่ได้ ให้แสดงข้อมูลเดิม
      }
      
      // จัดรูปแบบวันที่เป็น วัน/เดือน/ปี เวลา
      return date.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };
  
  // 3. ปรับปรุงฟังก์ชัน fetchFiles และ fetchFolders 
  const fetchFiles = useCallback(async (folderId) => {
    try {
      // เริ่มต้น loading
      setLoading(prev => ({ ...prev, files: true }));
      
      const url = `/folders/${folderId}/files`;
      const response = await axios.get(url);
      
      // สร้างการหน่วงเวลาเล็กน้อยก่อนแสดงข้อมูลจริง
      await new Promise(resolve => setTimeout(resolve, 500));
      
      setFiles(response.data.files || response.data);
    } catch (err) {
      showAlert('error', 'เกิดข้อผิดพลาด', 'ไม่สามารถโหลดรายการไฟล์ได้');
      
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      // สิ้นสุด loading ไม่ว่าจะสำเร็จหรือไม่ก็ตาม
      setLoading(prev => ({ ...prev, files: false }));
    }
  }, [navigate]);

  const fetchFolders = useCallback(async (parentId = null) => {
    try {
      // เริ่มต้น loading
      setLoading(prev => ({ ...prev, folders: true }));
      
      const currentUserData = JSON.parse(localStorage.getItem('currentUser'));
      console.log('Current user access level:', currentUserData?.access_level);
      
      const url = parentId 
        ? `/folders/${parentId}/subfolders`
        : '/folders';

      console.log('กำลังเรียก API:', url);
      const response = await axios.get(url);
      
      console.log('API Response status:', response.status);
      console.log('API Response data:', response.data);
      
      // สร้างการหน่วงเวลาเล็กน้อยก่อนแสดงข้อมูลจริง
      // เพื่อให้ loading skeleton แสดงได้สม่ำเสมอ ไม่กระพริบ
      await new Promise(resolve => setTimeout(resolve, 500));
      
      setFolders(response.data.folders || []);
    } catch (error) {
      console.error('Error in fetchFolders:', error);
      showAlert('error', 'เกิดข้อผิดพลาด', `ไม่สามารถโหลดข้อมูลโฟลเดอร์`);
    } finally {
      // สิ้นสุด loading ไม่ว่าจะสำเร็จหรือไม่ก็ตาม
      setLoading(prev => ({ ...prev, folders: false }));
    }
  }, []);
  
  // 4. ประกาศ initializeRootFolder ก่อน useEffect ที่จะใช้มัน
  const initializeRootFolder = useCallback(async () => {
    try {
      const response = await axios.get('/root-folder');
      
      if (response.data?.folder) {
        const rootFolder = response.data.folder;
        setCurrentFolderId(rootFolder.id);
        setFolderPath([]);
        fetchFolders(rootFolder.id);
        fetchFiles(rootFolder.id);
      } else {
        fetchFolders(null);
      }
    } catch (error) {
      console.error('Error initializing root folder:', error);
      fetchFolders(null);
    }
  }, [fetchFolders, fetchFiles]);
  
  // 5. ตามด้วย useEffect ที่ใช้ initializeRootFolder
  useEffect(() => {
    const isAdmin = !!localStorage.getItem('admin_token');
    
    if (!isAdmin) {
      initializeRootFolder();
    } else {
      fetchFolders(null);
    }
  }, [fetchFolders, initializeRootFolder]);
  
  // 6. เพิ่ม useEffect เพื่อจัดการการแสดง loading skeleton ให้มีเวลาแสดงที่นานพอ
  useEffect(() => {
    // กำหนดค่า minimum loading time สำหรับ skeleton
    const MINIMUM_LOADING_TIME = 800; // มิลลิวินาที
    
    // เพิ่มตัวแปร state สำหรับเก็บเวลาเริ่มต้น loading
    const loadingStartTime = {
      folders: Date.now(),
      files: Date.now()
    };
    
    // สำหรับโฟลเดอร์
    if (loading.folders) {
      loadingStartTime.folders = Date.now();
    } else if (!loading.folders && folders.length >= 0) {
      const elapsedTime = Date.now() - loadingStartTime.folders;
      if (elapsedTime < MINIMUM_LOADING_TIME) {
        // ถ้าเวลาผ่านไปน้อยกว่าที่กำหนด ให้รอแสดง skeleton ต่อจนครบ
        const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;
        const timer = setTimeout(() => {
          // ไม่ต้องทำอะไร แค่รอให้ครบเวลา
        }, remainingTime);
        
        return () => clearTimeout(timer);
      }
    }
    
    // สำหรับไฟล์
    if (loading.files) {
      loadingStartTime.files = Date.now();
    } else if (!loading.files && files.length >= 0) {
      const elapsedTime = Date.now() - loadingStartTime.files;
      if (elapsedTime < MINIMUM_LOADING_TIME) {
        // ถ้าเวลาผ่านไปน้อยกว่าที่กำหนด ให้รอแสดง skeleton ต่อจนครบ
        const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;
        const timer = setTimeout(() => {
          // ไม่ต้องทำอะไร แค่รอให้ครบเวลา
        }, remainingTime);
        
        return () => clearTimeout(timer);
      }
    }
  }, [loading.folders, loading.files, folders.length, files.length]);
  
  // 7. ตามด้วยฟังก์ชันอื่นๆ ที่ใช้ initializeRootFolder
  const handleFolderClick = async (folder) => {
    setCurrentFolderId(folder.id);
    
    const isRootFolder = folder.name === 'Root' && !folder.parent_id;
    
    if (isRootFolder) {
      setFolderPath([]);
    } else {
      setFolderPath(prev => [...prev, folder]);
    }
    
    await Promise.all([
      fetchFolders(folder.id),
      fetchFiles(folder.id)
    ]);
  };
  
  const handleBack = () => {
    const isAdmin = !!localStorage.getItem('admin_token');
    
    if (folderPath.length > 0) {
      const newPath = folderPath.slice(0, folderPath.length - 1);
      setFolderPath(newPath);
      
      if (newPath.length > 0) {
        const previousFolder = newPath[newPath.length - 1];
        setCurrentFolderId(previousFolder.id);
        fetchFolders(previousFolder.id);
        fetchFiles(previousFolder.id);
      } else {
        if (isAdmin) {
          setCurrentFolderId(null);
          fetchFolders(null);
          setFiles([]);
        } else {
          initializeRootFolder();
        }
      }
    } else {
      if (isAdmin) {
        setCurrentFolderId(null);
        fetchFolders(null);
        setFiles([]);
      } else {
        initializeRootFolder();
      }
    }
  };

  // 8. ฟังก์ชันอื่นๆ
  const confirmAction = (title, text, confirmText = 'ใช่, ดำเนินการ', cancelText = 'ยกเลิก') => {
    return Swal.fire({
      title, text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText
    });
  };

  const showLoading = (title = 'กำลังดำเนินการ...', text = 'โปรดรอสักครู่') => {
    return Swal.fire({
      title, text,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => Swal.showLoading(),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      handleUpload(acceptedFiles);
    },
    multiple: true,
    noClick: true,
    noKeyboard: true
  });

  // แก้ไขฟังก์ชัน handleUpload
const handleUpload = async (acceptedFiles) => {
  try {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      // ใช้ toast สำหรับข้อผิดพลาดเล็กน้อย - ไม่ได้เลือกไฟล์
      showAlert('error', 'เกิดข้อผิดพลาด', 'ไม่ได้เลือกไฟล์');
      return;
    }

    // ใช้ loading สำหรับกระบวนการที่ใช้เวลานาน
    showLoading('กำลังอัพโหลด...', 'โปรดรอสักครู่');

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('folderId', currentFolderId || '');

    let token = localStorage.getItem('token');
    if (isAdmin() && localStorage.getItem('admin_token')) {
      token = localStorage.getItem('admin_token');
      console.log('Using admin token for upload');
    }

    // เปลี่ยนจาก fetch เป็น axios เพื่อให้จัดการ error ได้ดีขึ้น
    // และแก้ไข URL ให้ถูกต้องโดยเพิ่ม /api ข้างหน้า
    const response = await axios.post('/upload', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    Swal.close(); // ปิด loading

    // ตรวจสอบ response
    if (response.status === 200 || response.status === 201) {
      // ใช้ toast สำหรับยืนยันการดำเนินการสำเร็จ
      showAlert('success', 'สำเร็จ', 'อัพโหลดไฟล์เรียบร้อยแล้ว');
      fetchFiles(currentFolderId);
    } else {
      throw new Error(response.data.message || 'การอัพโหลดไฟล์ล้มเหลว');
    }
  } catch (err) {
    Swal.close(); // ปิด loading

    console.error('Error uploading file:', err);

    // ปรับข้อความ error ให้เข้าใจง่าย
    let errorMessage = 'ไม่สามารถอัพโหลดไฟล์ได้';
    
    if (err.response) {
      // กรณีมี response จาก server (HTTP error)
      errorMessage += `: ${err.response.data?.message || err.response.statusText}`;
    } else if (err.request) {
      // กรณีไม่มี response จาก server (Network error)
      errorMessage += ': ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้';
    } else {
      // กรณีอื่นๆ
      errorMessage += err.message ? `: ${err.message}` : '';
    }

    // ใช้ modal สำหรับข้อผิดพลาดในการอัพโหลด (อาจมีรายละเอียดที่สำคัญ)
    showAlert('error', 'เกิดข้อผิดพลาดในการอัพโหลด', errorMessage, 'modal');
  }
};

  const handleDownload = async (fileId, fileName) => {
    try {
      // แสดงสถานะการโหลด
      showLoading('กำลังเตรียมการดาวน์โหลด...', 'โปรดรอสักครู่');
      
      const token = localStorage.getItem('token');
      const response = await fetch(`/download/${fileId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      
      if (!response.ok) throw new Error('ดาวน์โหลดไฟล์ไม่สำเร็จ');
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับแจ้งความสำเร็จ
      showAlert('success', 'สำเร็จ', 'เริ่มต้นดาวน์โหลดไฟล์แล้ว');
    } catch (err) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดในการดาวน์โหลด
      showAlert('error', 'เกิดข้อผิดพลาดในการดาวน์โหลด', err.message, 'modal');
    }
  };

  const handleDelete = async (fileId) => {
    try {
      // ขั้นตอนการยืนยัน
      const confirmation = await confirmAction(
        'คุณแน่ใจหรือไม่?',
        'คุณต้องการลบไฟล์นี้หรือไม่? ไฟล์จะถูกย้ายไปถังขยะ',
        'ใช่, ลบเลย',
        'ยกเลิก'
      );
      
      if (!confirmation.isConfirmed) return;
  
      // แสดง Loading
      showLoading('กำลังลบไฟล์...', 'โปรดรอสักครู่');
      
      const token = localStorage.getItem('token');
      
      // แก้ไข URL จาก /files/${fileId} เป็น http://localhost:5000/api/files/${fileId}
      // หรือใช้ axios ที่มีการกำหนดค่า baseURL ไว้แล้ว
      const response = await axios.delete(`/files/${fileId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      // ตรวจสอบสถานะ response
      if (response.status !== 200) {
        throw new Error('ลบไฟล์ไม่สำเร็จ');
      }
      
      Swal.close();
      showAlert('success', 'สำเร็จ', 'ไฟล์ถูกลบสำเร็จ');
      await fetchFiles(currentFolderId); // รีโหลดรายการไฟล์
    } catch (err) {
      console.error("Error deleting file:", err);
      Swal.close();
      showAlert('error', 'เกิดข้อผิดพลาดในการลบไฟล์', err.message || 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', 'modal');
    }
  };

  // แก้ไขฟังก์ชัน handleCreateFolder เพื่อให้แสดง debug info
const handleCreateFolder = async (folderName, accessLevel) => {
  if (!folderName) {
    // ใช้ toast สำหรับข้อผิดพลาดในการกรอกข้อมูล
    showAlert('error', 'กรุณากรอกชื่อโฟลเดอร์', '');
    return;
  }
  
  try {
    // แสดงสถานะการโหลด
    showLoading('กำลังสร้างโฟลเดอร์...', 'โปรดรอสักครู่');
    
    console.log(`กำลังสร้างโฟลเดอร์ '${folderName}' ใน parent_id:`, currentFolderId);
    console.log('access_level:', accessLevel);
    
    const response = await axios.post('/folders', {
      name: folderName,
      parent_id: currentFolderId,
      access_level: accessLevel
    });
    
    console.log('Create folder response status:', response.status);
    console.log('Response data:', response.data);
    
    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ toast สำหรับแจ้งความสำเร็จ
    showAlert('success', 'สำเร็จ', 'สร้างโฟลเดอร์เรียบร้อยแล้ว');
    fetchFolders(currentFolderId);
  } catch (error) {
    Swal.close(); // ปิดสถานะการโหลด
    console.error('Error creating folder:', error);
    // ใช้ modal สำหรับข้อผิดพลาดสำคัญ
    showAlert('error', 'เกิดข้อผิดพลาดในการสร้างโฟลเดอร์', error.message, 'modal');
  } finally {
    handleCloseModal();
  }
};

const handleDeleteFolder = async (folderId) => {
  try {
    // ใช้ confirmAction สำหรับการยืนยันการลบโฟลเดอร์ที่อาจมีไฟล์ข้างใน
    const confirmation = await confirmAction(
      'คุณแน่ใจหรือไม่?',
      'การลบโฟลเดอร์จะลบไฟล์และโฟลเดอร์ย่อยทั้งหมด และย้ายไปถังขยะ',
      'ใช่, ลบเลย',
      'ยกเลิก'
    );
    
    if (!confirmation.isConfirmed) return;

    // แสดงสถานะการโหลด
    showLoading('กำลังลบโฟลเดอร์...', 'โปรดรอสักครู่');
    
    const token = localStorage.getItem('token');
    
    // เปลี่ยนจาก fetch เป็น axios และใช้ URL ที่ถูกต้อง
    const response = await axios.delete(`/folders/${folderId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    // ตรวจสอบสถานะ response
    if (response.status !== 200) {
      throw new Error('ลบโฟลเดอร์ไม่สำเร็จ');
    }
    
    Swal.close();
    showAlert('success', 'สำเร็จ', 'ลบโฟลเดอร์เรียบร้อยแล้ว');
    fetchFolders(currentFolderId);
  } catch (err) {
    console.error("Error deleting folder:", err);
    Swal.close();
    showAlert('error', 'เกิดข้อผิดพลาดในการลบโฟลเดอร์', err.message || 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', 'modal');
  }
};



  
  const handleShowCreateFolderModal = () => {
    console.log("Opening create folder modal with parentFolderAccessLevel:", parentFolderAccessLevel);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // ปรับปรุงฟังก์ชัน handleEditAccess ให้ตรวจสอบสิทธิ์อย่างเข้มงวด
  const handleEditAccess = (item, isFolder = true) => {
    // ตรวจสอบสิทธิ์ทุกครั้งก่อนดำเนินการต่อ
    if (!isAdmin()) {
      showAlert('error', 'ไม่มีสิทธิ์', 'คุณไม่มีสิทธิ์ในการแก้ไขสิทธิ์การเข้าถึง');
      return;
    }
    
    setSelectedItem({ ...item, isFolder });
    setIsEditAccessModalOpen(true);
  };

  // แก้ไขทุกจุดที่ใช้ handleUpdateAccess ให้ตรวจสอบสิทธิ์เช่นกัน
  const handleUpdateAccess = async (itemId, accessLevel, isFolder) => {
    // ตรวจสอบสิทธิ์อีกครั้งก่อนทำการอัพเดตไปยัง API
    if (!isAdmin()) {
      showAlert('error', 'ไม่มีสิทธิ์', 'คุณไม่มีสิทธิ์ในการแก้ไขสิทธิ์การเข้าถึง');
      setIsEditAccessModalOpen(false);
      return;
    }

    try {
      // แสดงสถานะการโหลด
      showLoading('กำลังอัปเดตสิทธิ์...', 'โปรดรอสักครู่');
      
      const endpoint = isFolder 
        ? `/folders/${itemId}/access`
        : `/files/${itemId}/access`;
  
        // eslint-disable-next-line
      const response = await axios.put(endpoint, { access_level: accessLevel });
  
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับแจ้งความสำเร็จ
      showAlert('success', 'สำเร็จ', 'อัปเดตสิทธิ์การเข้าถึงเรียบร้อยแล้ว');
  
      // รีโหลดข้อมูล
      if (isFolder) {
        fetchFolders(currentFolderId);
      } else {
        fetchFiles(currentFolderId);
      }
    } catch (error) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดเกี่ยวกับสิทธิ์ (เรื่องสำคัญ)
      showAlert('error', 'เกิดข้อผิดพลาดในการอัปเดตสิทธิ์', error.message, 'modal');
    } finally {
      setIsEditAccessModalOpen(false);
    }
  };

  // แก้ไขฟังก์ชัน isAdmin ให้ตรวจสอบทั้ง admin_token และ access_level
const isAdmin = () => {
  const adminToken = localStorage.getItem('admin_token');
  if (adminToken) return true; // ถ้ามี admin_token ถือว่าเป็น admin
  
  // ตรวจสอบว่าผู้ใช้ปัจจุบันมี access_level เป็น 'admin' หรือไม่
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  return currentUser?.access_level === 'admin';
};

// เพิ่มฟังก์ชันสำหรับเปิดโมดัลแก้ไขชื่อ
const handleShowRenameModal = (folder) => {
  setFolderToRename(folder);
  setNewFolderName(folder.name);
  setIsRenameModalOpen(true);
};

// เพิ่มฟังก์ชันสำหรับปิดโมดัลแก้ไขชื่อ
const handleCloseRenameModal = () => {
  setIsRenameModalOpen(false);
  setFolderToRename(null);
  setNewFolderName('');
};

// เพิ่มฟังก์ชันสำหรับอัปเดตชื่อโฟลเดอร์
const handleRenameFolder = async () => {
  if (!folderToRename || !newFolderName.trim()) {
    // ใช้ toast สำหรับข้อผิดพลาดในการกรอกข้อมูล
    showAlert('error', 'กรุณากรอกข้อมูล', 'กรุณากรอกชื่อโฟลเดอร์');
    return;
  }

  try {
    // แสดงสถานะการโหลด
    showLoading('กำลังเปลี่ยนชื่อโฟลเดอร์...', 'โปรดรอสักครู่');
    
    // eslint-disable-next-line
    const response = await axios.put(`/folders/${folderToRename.id}/rename`, { name: newFolderName });

    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ toast สำหรับแจ้งความสำเร็จ
    showAlert('success', 'สำเร็จ', 'เปลี่ยนชื่อโฟลเดอร์เรียบร้อยแล้ว');

    // อัปเดตชื่อโฟลเดอร์ใน folderPath ถ้ามีการกดเข้าไปในโฟลเดอร์ที่กำลังเปลี่ยนชื่อ
    if (folderPath.some(folder => folder.id === folderToRename.id)) {
      setFolderPath(folderPath.map(folder => 
        folder.id === folderToRename.id 
          ? { ...folder, name: newFolderName } 
          : folder
      ));
    }

    // รีโหลดข้อมูลโฟลเดอร์
    fetchFolders(currentFolderId);
  } catch (error) {
    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ modal สำหรับข้อผิดพลาดในระบบ
    showAlert('error', 'เกิดข้อผิดพลาด', error.message, 'modal');
  } finally {
    handleCloseRenameModal();
  }
};

  // กำหนด parentFolderAccessLevel จาก folderPath หากมีข้อมูล
  const parentFolderAccessLevel = folderPath.length > 0 
    ? folderPath[folderPath.length - 1].access_level 
    : currentUser?.access_level || 'all';

  // เพิ่ม console.log เพื่อ debug
  useEffect(() => {
    console.log("Current parentFolderAccessLevel:", parentFolderAccessLevel);
  }, [parentFolderAccessLevel]);

  // ฟังก์ชันสำหรับตรวจสอบว่าอยู่ในโฟลเดอร์ Root หรือไม่
  const isRootFolder = () => {
    return currentFolderId && folderPath.length === 0;
  };

  // แก้ไขส่วนหัวข้อและเพิ่มเนื้อหาในหน้าแรก

return (
  <div className="max-w-6xl mx-auto p-6 min-h-screen bg-gray-50">
    {/* ปรับปรุงส่วนหัวข้อให้สวยงามและมีเนื้อหามากขึ้น */}
    <div className="mb-10 mt-6">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400 mb-2">
          File Manager
        </h1>
        <p className="text-gray-500 text-lg max-w-2xl mx-auto">
          จัดเก็บ แบ่งปัน และจัดการเอกสารของคุณอย่างมีประสิทธิภาพ
        </p>
      </div>
    </div>

    {/* แสดงเฉพาะกรณีไม่มี currentFolderId และกำลังไม่โหลดข้อมูล เพื่อไม่ให้กระพริบ */}
    {!currentFolderId && !loading.folders && (
      <div className="mb-8 px-5 py-6 bg-white rounded-xl shadow-md border border-gray-200">
        <h3 className="text-2xl font-bold text-gray-800 flex items-center mb-6">
          <Folder className={`${ICON_SIZES.medium} text-blue-500 mr-3`} />
          โฟลเดอร์ทั้งหมด
        </h3>
        
        <p className="text-gray-600 mb-6">
          เลือกโฟลเดอร์ด้านล่างเพื่อเข้าถึงไฟล์และโฟลเดอร์ย่อยภายใน
        </p>
      </div>
    )}

    {/* Current Folder Header - แสดงเมื่ออยู่ในโฟลเดอร์ */}
    {currentFolderId && !isRootFolder() && (
      <div className="mb-8 px-5 py-6 bg-blue-50 rounded-xl shadow-md border border-blue-200 transition-all">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="flex items-center mb-3 md:mb-0">
            <div className="bg-blue-500 p-3 rounded-lg shadow-md mr-6">
              <Folder className={`${ICON_SIZES.medium} text-white`} />
            </div>
            <div>
              <h3 className="text-2xl font-bold text-gray-800 flex items-center">
                <span className="truncate max-w-md">
                  {folderPath.length > 0 
                    ? folderPath[folderPath.length - 1].name 
                    : (currentFolderId ? 'Root' : 'หน้าหลัก')}
                </span>
              </h3>
              {folderPath.length > 0 && (
                <div className="mt-1">
                  {renderAccessLevelBadges(folderPath[folderPath.length - 1].access_level)}
                </div>
              )}
              {folderPath.length > 0 && folderPath[folderPath.length - 1].access_level !== 'all' && (
                <div className="mt-1 text-xs text-blue-700">
                  หมายเหตุ: โฟลเดอร์ที่สร้างภายในนี้จะมีสิทธิ์การเข้าถึงไม่เกินกว่าโฟลเดอร์นี้
                </div>
              )}
            </div>
          </div>
          <button
            onClick={handleBack}
            className="bg-white text-blue-600 hover:text-blue-800 py-2 px-4 rounded-lg border border-blue-200 shadow-sm hover:bg-blue-50 transition flex items-center"
          >
            <ArrowLeft className={ICON_SIZES.default} />
            <span className="ml-1 font-medium">ย้อนกลับ</span>
          </button>
        </div>

        {/* Path indicator - แสดงเพียงครั้งเดียว */}
        <div className="mt-4 flex flex-wrap items-center">
          <span 
            className="bg-white px-3 py-1 rounded-md shadow-sm border border-blue-200 text-sm text-blue-800 font-medium mr-2 mb-2 flex items-center cursor-pointer"
            onClick={() => {
              // ตรวจสอบว่าผู้ใช้เป็น admin หรือไม่
              const isAdmin = !!localStorage.getItem('admin_token');
              
              if (isAdmin) {
                // Admin กลับไปหน้าแสดงโฟลเดอร์ทั้งหมด
                setCurrentFolderId(null);
                setFolderPath([]);
                fetchFolders(null);
                setFiles([]);
              } else {
                // ผู้ใช้ทั่วไปกลับไปที่โฟลเดอร์ Root
                initializeRootFolder();
              }
            }}
          >
            <Folder className="w-4 h-4 mr-1" />
            หน้าหลัก
          </span>
          {folderPath.map((folder, index) => (
            <div key={`breadcrumb-${folder.id}`} className="flex items-center mr-2 mb-2">
              <span className="text-gray-500 mx-1">/&nbsp;</span>
              <span 
                className={`px-3 py-1 rounded-md shadow-sm text-sm font-medium flex items-center cursor-pointer
                  ${index === folderPath.length - 1 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-white text-blue-800 border border-blue-200'}`}
                onClick={() => {
                  const newPath = folderPath.slice(0, index + 1);
                  setFolderPath(newPath);
                  setCurrentFolderId(folder.id);
                  fetchFolders(folder.id);
                  fetchFiles(folder.id);
                }}
              >
                {index === folderPath.length - 1 && <Folder className="w-4 h-4 mr-1" />}
                {folder.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* แก้ไขเงื่อนไขการแสดง Combined Control & Folder View Toggle */}
    <div className="flex justify-between items-center mb-8 px-4">
      {/* Left-side: ปุ่มสร้างโฟลเดอร์ และ อัพโหลดไฟล์ (เป็นตัวหนังสือ) */}
      <div className="flex space-x-4">
        {/* ซ่อนปุ่มสร้างโฟลเดอร์เมื่ออยู่ในโฟลเดอร์ Root */}
        {!isRootFolder() && (
          <button
            onClick={handleShowCreateFolderModal}
            className="bg-white text-blue-600 py-2 px-4 rounded-lg border border-blue-400 shadow-sm hover:bg-blue-50 transition flex items-center space-x-2"
          >
            <Folder className={ICON_SIZES.default} />
            <span className="font-medium">สร้างโฟลเดอร์</span>
          </button>
        )}
        {/* ซ่อนปุ่มอัพโหลดไฟล์เมื่ออยู่ในโฟลเดอร์ Root */}
        {currentFolderId && !isRootFolder() && (
          <button
            type="button"
            onClick={() => hiddenFileInput.current.click()}
            className="bg-white text-blue-600 py-2 px-4 rounded-lg border border-blue-400 shadow-sm hover:bg-blue-50 transition flex items-center space-x-2"
          >
            <Download className={ICON_SIZES.default} />
            <span className="font-medium">อัพโหลดไฟล์</span>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleUpload(Array.from(e.target.files))}
              className="hidden"
              multiple
            />
          </button>
        )}
      </div>
      {/* Right-side: ปุ่มเปลี่ยนมุมมองโฟลเดอร์ (ซ่อนไว้เมื่อ folders ว่าง) */}
      {folders.length > 0 && (
        <div className="flex space-x-2">
          <button
            className={`p-2 rounded flex items-center justify-center ${foldersViewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600'}`}
            onClick={() => setFoldersViewMode('grid')}
            title="มุมมองตาราง"
          >
            <LayoutGrid className={ICON_SIZES.default} />
          </button>
          <button
            className={`p-2 rounded flex items-center justify-center ${foldersViewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600'}`}
            onClick={() => setFoldersViewMode('list')}
            title="มุมมองรายการ"
          >
            <List className={ICON_SIZES.default} />
          </button>
        </div>
      )}
    </div>

    {/* Folder Section */}
    {(folders.length > 0 || loading.folders) && (
      <>
        {foldersViewMode === 'grid' ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 px-4">
            {loading.folders ? (
              // แสดง skeleton ในแบบ grid
              Array(6).fill(0).map((_, index) => (
                <FolderSkeleton key={`folder-skeleton-${index}`} variant="grid" />
              ))
            ) : (
              folders.map((folder) => (
                <div
                  key={folder.id}
                  className="border p-4 bg-white rounded-lg shadow hover:bg-gray-100 transition"
                >
                  <div className="flex justify-between items-start">
                    <div 
                      className="flex-grow cursor-pointer" 
                      onClick={() => handleFolderClick(folder)}
                    >
                      <div className="flex items-center mb-2">
                        <Folder className={`${ICON_SIZES.medium} text-blue-500 mr-2`} />
                        <h3 className="text-xl font-medium text-gray-800 line-clamp-1">{folder.name}</h3>
                      </div>
                      <div className="mt-2 min-h-[20px] flex justify-start w-full">
                        {renderAccessLevelBadges(folder.access_level)}
                      </div>
                    </div>
                    
                    {/* Dropdown menu with ellipsis button */}
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                        <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                      </label>
                      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                        <li>
                          <button 
                            onClick={() => handleShowRenameModal(folder)}
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Edit2 className="h-4 w-4 mr-2 text-blue-500" />
                            <span>เปลี่ยนชื่อ</span>
                          </button>
                        </li>
                        {/* เฉพาะ Admin เท่านั้นที่เห็นปุ่มแก้ไขสิทธิ์ */}
                        {isAdmin() && (
                          <li>
                            <button 
                              onClick={() => handleEditAccess(folder)}
                              className="flex items-center px-3 py-2 hover:bg-gray-100"
                            >
                              <Users className="h-4 w-4 mr-2 text-blue-500" />
                              <span>แก้ไขสิทธิ์</span>
                            </button>
                          </li>
                        )}
                        <li>
                          <button 
                            onClick={() => handleDeleteFolder(folder.id)} 
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                            <span>ลบโฟลเดอร์</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="container mx-auto px-4">
            <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
              {/* ส่วนหัว */}
              <div className="flex items-center bg-gray-100 py-3 rounded-t-lg">
                <div className="w-2/3 px-4 text-left">
                  <span className="text-sm font-medium text-gray-700">ชื่อโฟลเดอร์</span>
                </div>
                <div className="w-1/3 px-4 flex justify-center">
                  <span className="text-sm font-medium text-gray-700">จัดการ</span>
                </div>
              </div>
              
              {/* ส่วนของข้อมูล */}
              <div className="divide-y divide-gray-200">
                {loading.folders ? (
                  // แสดง skeleton ในแบบ list
                  Array(6).fill(0).map((_, index) => (
                    <FolderSkeleton key={`folder-skeleton-${index}`} />
                  ))
                ) : (
                  folders.map((folder) => (
                    <div
                      key={folder.id}
                      className="flex hover:bg-gray-50 transition"
                    >
                      <div 
                        className="w-2/3 px-4 py-3 cursor-pointer"
                        onClick={() => handleFolderClick(folder)}
                      >
                        <div className="flex items-center min-h-[40px]">
                          <div className="flex items-center justify-center w-6 h-6 mr-2">
                            <Folder className={`${ICON_SIZES.default} text-blue-500`} />
                          </div>
                          <span className="font-medium text-gray-700">{folder.name}</span>
                        </div>
                        <div className="mt-1 min-h-[20px] flex justify-start w-full">
                          {renderAccessLevelBadges(folder.access_level)}
                        </div>
                      </div>
                      
                      <div className="w-1/3 px-4 py-3 flex items-center justify-center">
                        <div className="dropdown dropdown-end">
                          <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                            <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                          </label>
                          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                            <li>
                              <button 
                                onClick={() => handleShowRenameModal(folder)}
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Edit2 className="h-4 w-4 mr-2 text-blue-500" />
                                <span>เปลี่ยนชื่อ</span>
                              </button>
                            </li>
                            {isAdmin() && (
                              <li>
                                <button 
                                  onClick={() => handleEditAccess(folder)}
                                  className="flex items-center px-3 py-2 hover:bg-gray-100"
                                >
                                  <Users className="h-4 w-4 mr-2 text-blue-500" />
                                  <span>แก้ไขสิทธิ์</span>
                                </button>
                              </li>
                            )}
                            <li>
                              <button 
                                onClick={() => handleDeleteFolder(folder.id)} 
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                <span>ลบโฟลเดอร์</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )}

    {/* แสดงข้อความเมื่อไม่มีข้อมูลในโฟลเดอร์ - แสดงเฉพาะเมื่อโหลดเสร็จแล้ว และไม่มีข้อมูล */}
    {currentFolderId && folders.length === 0 && files.length === 0 && !loading.folders && !loading.files && (
      <div className="flex flex-col items-center justify-center py-12 px-4 sm:p-24 bg-white rounded-lg shadow-sm mt-6 mx-4">
        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-6">
          <Folder className="w-8 h-8 text-gray-400" />
        </div>
        <h4 className="text-xl font-semibold text-gray-700 mb-4 text-center">ไม่มีข้อมูลในโฟลเดอร์นี้</h4>
        <p className="text-gray-500 text-center max-w-xs">
          คุณสามารถสร้างโฟลเดอร์ใหม่หรืออัพโหลดไฟล์ได้
        </p>
        
        {/* เพิ่มปุ่มที่ชัดเจนสำหรับมือถือ */}
        <div className="mt-8 flex flex-col sm:flex-row gap-3 w-full max-w-xs">
          <button
            onClick={handleShowCreateFolderModal}
            className="flex items-center justify-center px-4 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors w-full"
          >
            <Folder className="h-5 w-5 mr-2" />
            สร้างโฟลเดอร์
          </button>
          <button 
            onClick={() => hiddenFileInput.current.click()}
            className="flex items-center justify-center px-4 py-3 bg-white text-blue-600 border border-blue-500 rounded-md hover:bg-blue-50 transition-colors w-full"
          >
            <Download className="h-5 w-5 mr-2" />
            อัพโหลดไฟล์
          </button>
        </div>
      </div>
    )}

    {/* แสดงข้อความเมื่อไม่มีไฟล์ในโฟลเดอร์ แต่มีโฟลเดอร์ย่อย - ไม่แสดงในหน้าแรกและเมื่อกำลังโหลด */}
    {currentFolderId && !isRootFolder() && folders.length > 0 && files.length === 0 && !loading.files && (
      <div className="flex flex-col items-center justify-center py-8 px-4 sm:p-8 bg-white rounded-lg shadow-sm mt-16 mx-4">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
          <FileText className="w-6 h-6 text-gray-400" />
        </div>
        <h4 className="text-lg font-semibold text-gray-700 mb-3 text-center">ไม่มีไฟล์ในโฟลเดอร์นี้</h4>
        <p className="text-gray-500 text-center max-w-xs mb-4">
          คุณสามารถอัพโหลดไฟล์ในโฟลเดอร์นี้ได้
        </p>
        <button 
          onClick={() => hiddenFileInput.current.click()}
          className="px-5 py-2.5 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors w-full max-w-[200px]"
        >
          <Download className="h-4 w-4 mr-2 inline-block" />
          เลือกไฟล์
        </button>
      </div>
    )}

    {/* Dropzone แบบไม่แสดงรายละเอียด แต่ยังคงทำงานตามปกติ เมื่อลากไฟล์เข้ามาจึงจะแสดงข้อความ */}
    <div {...getRootProps()} className={`fixed inset-0 ${isDragActive ? 'pointer-events-auto' : 'pointer-events-none'}`}>
      <input {...getInputProps()} multiple />
    </div>

    {/* แสดง Overlay เมื่อมีการลากไฟล์เข้ามา */}
    {isDragActive && (
      <div className="fixed inset-0 bg-blue-100 bg-opacity-80 z-50 flex items-center justify-center">
        <div className="bg-white p-10 rounded-xl shadow-2xl text-center animate-fadeIn">
          <svg 
            className="w-16 h-16 text-blue-500 mx-auto mb-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <h3 className="text-2xl font-bold text-gray-800 mb-2">ลากและวางไฟล์ที่นี่</h3>
          <p className="text-gray-600 mb-4">ปล่อยไฟล์เพื่ออัพโหลดไปยังโฟลเดอร์ {folderPath.length > 0 ? folderPath[folderPath.length - 1].name : 'ปัจจุบัน'}</p>
        </div>
      </div>
    )}

    {/* Files Section */}
    {currentFolderId && (
      <>
        {/* แสดงหัวข้อและปุ่มเฉพาะเมื่อไม่ได้ loading และมีไฟล์ */}
        {!loading.files && files.length > 0 && (
          <div className="flex justify-between items-center mb-4 mt-16 px-4">
            <h3 className="text-2xl font-bold text-gray-800 flex items-center">
              <FileText className={`${ICON_SIZES.medium} text-blue-500 mr-2`} />
              <span>ไฟล์ในโฟลเดอร์</span>
            </h3>
            <div className="flex space-x-2">
              <button
                className={`p-2 rounded flex items-center justify-center ${viewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => setViewMode('grid')}
                title="มุมมองตาราง"
              >
                <LayoutGrid className={ICON_SIZES.default} />
              </button>
              <button
                className={`p-2 rounded flex items-center justify-center ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => setViewMode('list')}
                title="มุมมองรายการ"
              >
                <List className={ICON_SIZES.default} />
              </button>
            </div>
          </div>
        )}
        
        {/* แสดงเฉพาะ Loading Skeleton เมื่อกำลัง loading */}
        {loading.files && (
          <div className="mt-16 px-4">
            {viewMode === 'list' ? (
              <div className="w-full">  {/* ลบ overflow-x-auto และ overflow-y-visible */}
                <div className="bg-white rounded-lg shadow divide-y divide-gray-200 min-w-[768px]">
                  {Array(5).fill(0).map((_, index) => (
                    <FileSkeleton key={`file-skeleton-${index}`} />
                  ))}
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array(6).fill(0).map((_, index) => (
                  <FileSkeleton key={`file-skeleton-${index}`} variant="grid" />
                ))}
              </div>
            )}
          </div>
        )}
        
        {/* แสดงข้อมูลไฟล์จริงเมื่อโหลดเสร็จแล้ว */}
        {!loading.files && files.length > 0 && (
          viewMode === 'list' ? (
            <div className="w-full px-4">  {/* ลบ overflow-x-auto และ overflow-y-visible */}
              <div className="bg-white rounded-lg shadow divide-y divide-gray-200 min-w-[768px]">
                {/* ส่วนหัว */}
                <div className="flex items-center bg-gray-100 py-3 rounded-t-lg">
                  <div className="w-1/3 px-4 text-left">
                    <span className="text-sm font-medium text-gray-700">ชื่อไฟล์</span>
                  </div>
                  <div className="w-1/3 px-4 text-left">
                    <span className="text-sm font-medium text-gray-700">วันที่อัพโหลด</span>
                  </div>
                  <div className="w-1/3 px-4 flex justify-center">
                    <span className="text-sm font-medium text-gray-700">จัดการ</span>
                  </div>
                </div>
                
                {/* ส่วนของข้อมูล */}
                <div className="divide-y divide-gray-200">
                  {files.map((file) => (
                    <div
                      key={file.id}
                      className="flex items-center hover:bg-gray-50 transition"
                    >
                      {/* ชื่อไฟล์ */}
                      <div className="w-1/3 px-4 py-3 min-h-[40px] flex items-center">
                        <div className="flex items-center space-x-2 max-w-full">
                          <div className="flex items-center justify-center w-6 h-6 flex-shrink-0">
                            {file.file_type.startsWith('image/')
                              ? <Image className={`${ICON_SIZES.default} text-blue-500`} />
                              : <FileText className={`${ICON_SIZES.default} text-blue-500`} />}
                          </div>
                          <span className="text-sm text-gray-700 truncate">{file.name}</span>
                        </div>
                      </div>
                      
                      {/* วันที่อัพโหลด */}
                      <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center">
                        <span className="text-sm text-gray-500">{formatDate(file.upload_date)}</span>
                      </div>
                      
                      {/* จัดการ */}
                      <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center justify-center">
                        <div className="dropdown dropdown-end">
                          <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                            <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                          </label>
                          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                            <li>
                              <button 
                                onClick={() => handleDownload(file.id, file.name)}
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Download className="h-4 w-4 mr-2 text-blue-500" />
                                <span>ดาวน์โหลด</span>
                              </button>
                            </li>
                            {/* เฉพาะ Admin เท่านั้นที่เห็นปุ่มแก้ไขสิทธิ์ */}
                            {isAdmin() && (
                              <li>
                                <button 
                                  onClick={() => handleEditAccess(file, false)}
                                  className="flex items-center px-3 py-2 hover:bg-gray-100"
                                >
                                  <Users className="h-4 w-4 mr-2 text-blue-500" />
                                  <span>แก้ไขสิทธิ์</span>
                                </button>
                              </li>
                            )}
                            <li>
                              <button 
                                onClick={() => handleDelete(file.id)}
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                <span>ลบไฟล์</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
              {files.map((file) => (
                <div key={file.id} className="border p-4 rounded-lg shadow bg-white transition hover:shadow-lg">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center space-x-2 overflow-hidden">
                      {file.file_type.startsWith('image/')
                        ? <Image className={`${ICON_SIZES.medium} text-blue-500 flex-shrink-0`} />
                        : <FileText className={`${ICON_SIZES.medium} text-blue-500 flex-shrink-0`} />}
                      <h4 className="font-medium text-gray-800 truncate">{file.name}</h4>
                    </div>
                    
                    {/* Dropdown menu for file actions */}
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                        <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                      </label>
                      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                        <li>
                          <button 
                            onClick={() => handleDownload(file.id, file.name)}
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Download className="h-4 w-4 mr-2 text-blue-500" />
                            <span>ดาวน์โหลด</span>
                          </button>
                        </li>
                        {isAdmin() && (
                          <li>
                            <button 
                              onClick={() => handleEditAccess(file, false)}
                              className="flex items-center px-3 py-2 hover:bg-gray-100"
                            >
                              <Users className="h-4 w-4 mr-2 text-blue-500" />
                              <span>แก้ไขสิทธิ์</span>
                            </button>
                          </li>
                        )}
                        <li>
                          <button 
                            onClick={() => handleDelete(file.id)}
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                            <span>ลบไฟล์</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="text-sm text-gray-500">{formatDate(file.upload_date)}</p>
                </div>
              ))}
            </div>
          )
        )}
      </>
    )}

    {/* ส่วน Modal */}
    <CreateFolderModal 
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onSubmit={handleCreateFolder}
      folderAccessLevel={parentFolderAccessLevel}
    />
    <EditAccessLevelModal
      isOpen={isEditAccessModalOpen}
      onClose={() => setIsEditAccessModalOpen(false)}
      onSubmit={handleUpdateAccess}
      item={selectedItem}
      isAdmin={isAdmin()}
    />
    {isRenameModalOpen && (
      <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4 animate-fadeIn">
          <h2 className="text-2xl font-bold mb-4">เปลี่ยนชื่อโฟลเดอร์</h2>
          <div className="mb-4">
            <label htmlFor="folderName" className="block text-gray-700 mb-2">
              ชื่อโฟลเดอร์
            </label>
            <input
              id="folderName"
              type="text"
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="ระบุชื่อโฟลเดอร์..."
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleCloseRenameModal}
              className="px-4 py-2 border rounded-md hover:bg-gray-100"
            >
              ยกเลิก
            </button>
            <button
              onClick={handleRenameFolder}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default FilesManager;